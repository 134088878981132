<script setup lang="ts" generic="T extends { id?: string }">
import {
  Cell, ColumnOrderState,
  FlexRender,
  getCoreRowModel,
  getSortedRowModel,
  Header, Row,
  RowData,
  SortingState,
  useVueTable, VisibilityState
} from '@tanstack/vue-table'
import { useElementSize, useResizeObserver } from '@vueuse/core'
import { useVirtualizer } from '@tanstack/vue-virtual'
import { InfiniteData } from '@tanstack/vue-query'
import { useSlots } from 'vue'
import SortButton from '~/components/molecules/SortButton.vue'
import Checkbox from '~/components/molecules/Checkbox.vue'
import Button from '~/components/molecules/Button.vue'
import Icon from '~/components/molecules/Icon.vue'
import { CheckboxType } from '~/src/enums/Checkbox'
import { ButtonType } from '~/src/enums/Button'
import { TableMetaData } from '~/src/types/TableMeta'

interface Props<T> {
  id: string,
  columns: T[],
  data: InfiniteData<T[], T>,
  isError?: boolean,
  isFetching?: boolean
  hasNextPage?: boolean
  infinite?: boolean,
  enableRowSelection?: boolean,
  enableMultiRowSelection?: boolean,
  debugTable?: boolean,
  class?: string,
  lineColumns?: number,
  disableLine?: boolean,
  errorMessage?: string,
  retries?: boolean | number,
  sorting?: SortingState
  order?: ColumnOrderState
  visibility?: VisibilityState
}

const props = withDefaults(defineProps<Props<T>>(), {
  infinite: true,
  isError: false,
  isFetching: false,
  enableRowSelection: true,
  enableMultiRowSelection: true,
  debugTable: false,
  lineColumns: 2,
  disableLine: true,
  errorMessage: 'We have an issue loading content. If the error persists, come back later when we hopefully have fixed it.'
})

// TODO: rewrite everything to use https://tanstack.com/table/v8/docs/guide/column-pinning instead of our custom solution

const sortingEnabled = computed(() => props.sorting)
const orderEnabled = computed(() => props.order)
const visibilityEnabled = computed(() => props.visibility)

const reactiveColumns = toRef(() => props.columns)

const localSorting = ref<SortingState>([])
const localOrder = ref<ColumnOrderState>([])
const localVisibility = ref<VisibilityState>({})

const emit = defineEmits(['fetchNextPage', 'sort', 'onRowClick'])

const flatData = computed<T[]>(() => props.data?.pages.flatMap(page => page) || [])

const table = useVueTable({
  get data () {
    return flatData.value
  },
  get columns () {
    return reactiveColumns.value as []
  },
  state: {
    get sorting () {
      return sortingEnabled.value ? props.sorting! : localSorting.value
    },
    get columnOrder () {
      return orderEnabled.value ? props.order! : localOrder.value
    },
    get columnVisibility () {
      return visibilityEnabled.value ? props.visibility! : localVisibility.value
    }
  },
  onSortingChange: (updaterOrValue) => {
    const value = sortingEnabled.value ? props.sorting! : localSorting.value

    const sortingValue = typeof updaterOrValue === 'function'
      ? updaterOrValue(value)
      : updaterOrValue

    if (sortingEnabled.value) {
      emit('sort', sortingValue)
    }
    localSorting.value = sortingValue
  },
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  enableRowSelection: props.enableRowSelection,
  manualSorting: !!sortingEnabled.value,
  enableMultiRowSelection: props.enableMultiRowSelection,
  debugTable: props.debugTable,
  getRowId: (row, index) => {
    if (row && row.id) { return row.id }
    return index.toString()
  }
})

const tableContainer = ref<HTMLDivElement | null>(null)
const tableElement = ref<HTMLTableElement | null>(null)
const headersRefs = ref<HTMLTableCellElement[]>([])

const slots = useSlots()

const rows = computed(() => table.getRowModel().rows)

const selectedRows = computed(() => table.getSelectedRowModel().flatRows.map(row => row.original))

const virtualizerOptions = computed(() => {
  return {
    count: rows.value.length,
    getScrollElement: () => tableElement.value,
    estimateSize: () => 60,
    overscan: 5,
    debug: props.debugTable
  }
})

const virtualizer = useVirtualizer(virtualizerOptions)

const virtualRows = computed(() => virtualizer.value.getVirtualItems())

const totalSize = computed(() => virtualizer.value.getTotalSize())

const allSelected = computed({
  get () {
    return table.getIsAllRowsSelected()
  },
  set () {
    table.toggleAllRowsSelected()
  }
})

const metaValue = (el: Header<RowData, unknown> | Cell<RowData, unknown>, property: string) => {
  const meta = el.column.columnDef?.meta

  if (meta && Object.hasOwn(meta, property)) {
    return meta[property as keyof typeof meta] as object
  }
  return true
}

const isSticky = (el: Header<RowData, T> | Cell<RowData, T>) => (
  (el.column.columnDef?.meta as TableMetaData)?.sticky ?? false)

const filteredHeaderGroups = computed(() => {
  return table.getHeaderGroups().map((group) => {
    group.headers = group.headers.filter(header => !header.isPlaceholder && metaValue(header, 'header'))
    return group
  })
})

const columnPositions = computed(() => {
  const headerGroup = filteredHeaderGroups.value.find(headerGroup => headerGroup.headers.length)
  const positions: { left: number, right: number }[] = []
  if (!headerGroup) {
    return positions
  }
  const headers = headerGroup.headers as Header<RowData, T>[]

  let currPosition = 0
  for (let i = headers.length - 1; i >= 0; i--) {
    const meta = headers[i].column.columnDef?.meta
    const position = { left: 0, right: 0 }
    if (!meta || !Object.hasOwn(meta, 'sticky') || !(meta as TableMetaData)!.sticky) {
      positions.unshift(position)
      continue
    }
    position.right = currPosition
    positions.unshift(position)

    const column = headersRefs.value.find(headersRef => (headersRef.cellIndex === i + 1))
    const width = column?.getBoundingClientRect()?.width || headers[i].column.columnDef.size || 0
    currPosition += width
  }

  currPosition = 0
  for (let i = 0; i < headers.length; i++) {
    const meta = headers[i].column.columnDef?.meta
    if (!meta || !Object.hasOwn(meta, 'sticky') || !(meta as TableMetaData)!.sticky) { continue }
    positions[i].left = currPosition
    const column = headersRefs.value.find(headersRef => (headersRef.cellIndex === i + 1))
    const width = column?.getBoundingClientRect()?.width || headers[i].column.columnDef.size || 0
    currPosition += width
  }
  return positions
})

const { width: tableWidth, height: tableHeight } = useElementSize(tableElement)
const { width: tableContainerWidth } = useElementSize(tableContainer)

const isTableFullWidth = computed(() => {
  return tableWidth.value === tableContainerWidth.value
})

const intersections = ref<{ [key: number]: { left: boolean, right: boolean } }>({})

const observeIntersections = () => {
  intersections.value = {}
  for (const current of headersRefs.value) {
    const isIntersecting = { left: false, right: false }
    const prev = current.previousElementSibling
    const next = current.nextElementSibling
    const { left: currentLeftBounding, right: currentRightBounding } = current.getBoundingClientRect()
    if (prev) {
      const prevRightBounding = prev.getBoundingClientRect().right
      // an intersection occurs if the current element overlaps with at least 1% of the neighboring element
      if (currentLeftBounding * 1.01 < prevRightBounding) {
        isIntersecting.left = true
      }
    }
    if (next) {
      const nextLeftBounding = next.getBoundingClientRect().left
      // an intersection occurs if the current element overlaps with at least 1% of the neighboring element
      if (currentRightBounding * 0.99 > nextLeftBounding) {
        isIntersecting.right = true
      }
    }
    intersections.value[current.cellIndex - 1] = isIntersecting
  }
}

useResizeObserver(tableElement, observeIntersections)

const { width } = useWindowSize()
watch(width, observeIntersections)

const shouldLastRowBeRounded = computed(() => {
  return !props.isFetching && props.infinite && !(slots.empty && virtualRows.value.length === 0) && !props.isError
})

const theadRef = ref<HTMLDivElement | null>(null)
const { height: theadHeight } = useElementSize(theadRef)

const hoveredRowIndex = ref<number | null>(null)
const isFirstRowHoveredOrSelected = computed(() => (hoveredRowIndex.value === 0 || rows.value[0].getIsSelected()))
const isLastRowHoveredOrSelected = computed(() => (hoveredRowIndex.value === rows.value.length - 1 || rows.value[rows.value.length - 1].getIsSelected()))

const bottomRef = ref<HTMLDivElement | null>(null)

const fetchNextPage = () => {
  if (!props.isFetching && props.hasNextPage) {
    emit('fetchNextPage')
  }
}

onMounted(() => {
  if (props.infinite) {
    useIntersectionObserver(
      bottomRef,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          fetchNextPage()
        }
      }
    )
  }
})

const { height } = useWindowSize()
// fetch next page if there is free space
watchEffect(() => {
  if (height.value - tableHeight.value > 0) {
    fetchNextPage()
  }
})

const onRowClick = (row: Row<unknown>) => {
  emit('onRowClick', row.original)
}
</script>

<template>
  <div class="w-full relative" style="min-width: 0" :class="props.class">
    <!-- workaround to add rounded corners -->
    <template v-if="virtualRows.length">
      <!-- top corners -->
      <div class="absolute w-1 z-10" :style="{ marginTop: `${theadHeight + 4}px` }">
        <div class="h-1 w-1 ml-1 bg-black-5">
          <div :class="`h-full w-1 rounded-tl ${ isFirstRowHoveredOrSelected ? 'bg-black-1' : 'bg-white-100' }`" />
        </div>
      </div>
      <div class="absolute right-0 w-1 z-10" :style="{ marginTop: `${theadHeight + 4}px` }">
        <div class="h-1 w-1 -ml-1 bg-black-5">
          <div :class="`h-full w-1 rounded-tr ${ isFirstRowHoveredOrSelected ? 'bg-black-1' : 'bg-white-100' }`" />
        </div>
      </div>
      <!-- bottom corners -->
      <div v-if="!isFetching" class="absolute w-1 z-10" :style="{ marginTop: `${tableHeight}px` }">
        <div class="h-1 w-1 ml-1 bg-black-5">
          <div :class="`h-full w-1 rounded-bl ${ isLastRowHoveredOrSelected ? 'bg-black-1' : 'bg-white-100' }`" />
        </div>
      </div>
      <div v-if="!isFetching" class="absolute right-0 w-1 z-10" :style="{ marginTop: `${tableHeight}px` }">
        <div class="h-1 w-1 -ml-1 bg-black-5">
          <div :class="`h-full w-1 rounded-br ${ isLastRowHoveredOrSelected ? 'bg-black-1' : 'bg-white-100' }`" />
        </div>
      </div>
    </template>
    <div class="p-1 bg-black-5 rounded-lg relative">
      <div
        ref="tableContainer"
        :class="[
          'relative scrollbar-hide',
          {
            'overflow-x-auto overflow-y-clip': !isTableFullWidth,
          }
        ]"
        @scroll="observeIntersections"
      >
        <table ref="tableElement" class="border-spacing-0">
          <thead ref="theadRef" class="bg-black-5 mb-0">
            <tr
              v-for="headerGroup in filteredHeaderGroups"
              :key="headerGroup.id"
              class="cursor-pointer"
            >
              <th
                v-if="props.enableMultiRowSelection && headerGroup.headers.length !== 0"
                ref="headersRefs"
                class="bg-black-5 sticky z-10 left-0"
                :style="{
                  width: '38px'
                }"
              >
                <Checkbox v-model="allSelected" class="!p-0" name="table-select-all" :type="CheckboxType.Secondary" />
                <div
                  v-if="intersections[-1]?.right"
                  class="absolute w-0.5 h-full bg-transparent top-0 right-0 z-20 ml-2 shadow-large-dense -scale-x-100"
                  style="clip-path: inset(0px 0 0px -12px);"
                />
              </th>
              <th
                v-for="(header, index) in headerGroup.headers"
                ref="headersRefs"
                :key="header.id"
                :class="{
                  'cursor-pointer select-none': header.column.getCanSort(),
                  'sticky z-10': isSticky(header)
                }"
                :style="{
                  minWidth: `${header.getSize()}px`,
                  ...metaValue(header, 'style') as {},
                  right: `${columnPositions[index].right}px`,
                  left: `${columnPositions[index].left}px`
                }"
                @click="header.column.getToggleSortingHandler()?.($event)"
              >
                <div
                  v-if="isSticky(header) && intersections[index]?.right"
                  class="absolute w-0.5 h-full bg-transparent top-0 right-0 z-20 ml-2 shadow-large-dense -scale-x-100"
                  style="clip-path: inset(0px 0 0px -12px);"
                />
                <div
                  v-if="isSticky(header) && intersections[index]?.left"
                  class="absolute w-0.5 h-full bg-transparent top-0 left-0 z-20 mr-2 shadow-large-dense"
                  style="clip-path: inset(0px 0 0px -12px);"
                />
                <div class="th-inner" :style="{...metaValue(header, 'innerStyle') as {}}">
                  <FlexRender
                    :render="header.column.columnDef.header"
                    :props="header.getContext()"
                  />
                  <SortButton v-if="header.column.getCanSort()" :header="header" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody :style="{ height: `${totalSize}px` }">
            <tr
              v-for="(virtualRow, index) in virtualRows"
              :key="rows[virtualRow.index]!.id"
              :class="[
                'cursor-pointer',
                {
                  'tr--selected': rows[virtualRow.index]!.getIsSelected(),
                  'tr--rounded-b': shouldLastRowBeRounded && (index === virtualRows.length - 1)
                }
              ]"
              @mouseenter="hoveredRowIndex = index"
              @mouseleave="hoveredRowIndex = null"
              @click="onRowClick(rows[virtualRow.index])"
            >
              <td
                v-if="props.enableRowSelection"
                class="sticky z-10 left-0"
                :style="{
                  width: '38px'
                }"
                @click.stop
              >
                <Checkbox
                  class="!p-0"
                  :name="`table-select-row-${rows[virtualRow.index]!.id}`"
                  :model-value="rows[virtualRow.index]!.getIsSelected()"
                  :type="CheckboxType.Secondary"
                  @update:model-value="rows[virtualRow.index]!.toggleSelected()"
                />
                <div
                  v-if="intersections[-1]?.right"
                  class="absolute w-0.5 h-full bg-transparent top-0 right-0 z-20 ml-2 shadow-large-dense -scale-x-100"
                  style="clip-path: inset(0px 0 0px -12px);"
                />
              </td>
              <td
                v-for="(cell, cellIndex) in rows[virtualRow.index]!.getVisibleCells()"
                :key="cell.id"
                :style="{
                  minWidth: `${cell.column.getSize()}px`,
                  ...metaValue(cell, 'style') as {},
                  right: `${columnPositions[cellIndex].right}px`,
                  left: `${columnPositions[cellIndex].left}px`
                }"
                :class="{
                  'sticky': isSticky(cell)
                }"
              >
                <div
                  v-if="isSticky(cell) && intersections[cellIndex]?.right"
                  class="absolute w-0.5 h-full bg-transparent top-0 right-0 z-20 ml-2 shadow-large-dense -scale-x-100"
                  style="clip-path: inset(0px 0 0px -12px);"
                />
                <div
                  v-if="isSticky(cell) && intersections[cellIndex]?.left"
                  class="absolute w-0.5 h-full bg-transparent top-0 left-0 z-20 mr-2 shadow-large-dense"
                  style="clip-path: inset(0px 0 0px -12px);"
                />
                <!-- It's not the most elegant solution, but let's not waste time here. -->
                <div :style="{...metaValue(cell, 'innerStyle') as {}}">
                  <FlexRender
                    :render="cell.column.columnDef.cell"
                    :props="cell.getContext()"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div ref="bottomRef" />
      <div :class="['table-footer', { 'rounded-t overflow-hidden': virtualRows.length === 0 }]">
        <div v-show="isFetching" class="flex justify-center bg-white-100 p-3 h-12 relative">
          <div class="spinner border-t-black-100" />
        </div>
        <div v-if="!props.infinite" class="flex justify-center py-2">
          <Button :type="ButtonType.Link" @click="emit('fetchNextPage')">
            <template #icon>
              <Icon name="load" />
            </template>
            Load More
          </Button>
        </div>
        <div v-if="slots.empty && virtualRows.length === 0 && !isFetching && !isError" class="w-full bg-white-100 p-3">
          <slot name="empty" />
        </div>
        <div v-if="isError && !isFetching" class="flex justify-center bg-white-100 p-3 text-body-regular text-black-75">
          <span class="w-[30rem] text-center">
            {{ props.errorMessage }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <Transition name="fade">
    <slot v-if="table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()" name="selectedComponent" :selected-rows="selectedRows" :table="table" />
  </Transition>
</template>

<style scoped lang="postcss">
table {
  @apply w-full relative border-collapse;

  thead {
    @apply sticky top-0 text-subheader text-black-100 uppercase z-20;
    th {
      @apply px-4 bg-black-5 w-auto h-[2.75rem];

      .th-inner {
        @apply flex items-center gap-2 whitespace-nowrap;
      }
    }
  }

  tbody {
    @apply overflow-clip rounded text-body-regular;

    tr {
      @apply bg-white-100 hover:bg-black-1 h-[3.75rem];

      &.tr--selected {
        @apply bg-black-1 border-black-10;
      }

      td {
        @apply bg-[inherit] px-4 w-auto;
      }

      &:first-child {
        td {
          &:first-child {
            @apply rounded-tl;
          }
          &:last-child {
            @apply rounded-tr;
          }
        }
      }

      &.tr--rounded-b {
        td {
          &:first-child {
            @apply rounded-bl;
          }
          &:last-child {
            @apply rounded-br;
          }
        }
      }

      &:not(:last-child) {
        @apply border-b border-b-black-5;
      }
    }
  }
}

.table-footer > *:last-child {
  @apply rounded-b;
}
</style>
